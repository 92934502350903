.ceil {
  width: 40px;
  height: 40px;
  border: 1px solid white;
}

.maze-grid {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.controls {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.maze-button {
  background-color: rgb(58, 88, 208);
  color: white;
  margin: 10px;
  cursor: pointer;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 5px;
}

.maze {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.row {
  display: flex;
  flex-direction: row;
}

.ceil.wall {
  background-color: black;
}

.ceil.end {
  background-color: red;
}

.ceil.path {
  background-color: white;
}

.ceil.start {
  background-color: green;
}

.ceil.visited {
  background-color: rgb(132, 215, 228);
}
